import React from 'react';
import { Hero } from './sections/Hero';
import { RotationLogo } from './sections/RotationLogos';
import { FeatureBlock } from './sections/FeatureBlock';
import { Footer } from './sections/Footer';
import { Navbar } from './sections/Navbar';
import Page from 'src/components/Page';
import { SplitScreen } from 'src/components/split-screen';
import SectionEditor from './SectionEditor';
import { useEditor } from './editor-context';

export default function Website() {
  const { selectedSectionId, selectSection } = useEditor();

  return (
    <Page
      title="Website"
      // @ts-ignore
      style={{ height: '100%' }}
    >
      <SplitScreen
        direction="horizontal"
        leftDefaultSize={50}
        leftPanel={
          <div className="flex h-full p-6">
            <div className="flex flex-col w-full gap-8">
              <SectionEditor />
            </div>
          </div>
        }
        rightDefaultSize={50}
        rightPanel={
          <div
            style={{
              padding: 40,
              backgroundColor: 'rgba(255,255,0,0.1)',
              height: 'calc(100vh - 64px)',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                backgroundColor: '#f4f4f5',
                position: 'relative',
                //   top: 0,
                //   transform: 'scale(0.5)',
                //   marginTop: '-70%',
                zoom: 0.5,
                borderRadius: '8px',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Navbar />

              <ActiveSection
                isSelected={selectedSectionId === 'hero'}
                key="hero"
                onMouseEnter={() => selectSection('hero')}
              >
                <Hero />
              </ActiveSection>
              <ActiveSection
                isSelected={selectedSectionId === 'logos'}
                key="logos"
                onMouseEnter={() => selectSection('logos')}
              >
                <RotationLogo />
              </ActiveSection>
              <ActiveSection
                isSelected={selectedSectionId === 'feature'}
                key="feature"
                onMouseEnter={() => selectSection('feature')}
              >
                <FeatureBlock />
              </ActiveSection>
              <ActiveSection
                isSelected={selectedSectionId === 'footer'}
                key="footer"
                onMouseEnter={() => selectSection('footer')}
              >
                <Footer />
              </ActiveSection>
            </div>
          </div>
        }
      />
    </Page>
  );
}

function ActiveSection({
  isSelected,
  children,
  onMouseEnter,
}: {
  isSelected: boolean;
  children: React.ReactNode;
  onMouseEnter: () => void;
}) {
  return (
    <div
      onMouseEnter={onMouseEnter}
      style={{
        transition: 'transform 0.3s',
        position: 'relative',
        opacity: isSelected ? 1 : 0.5,
        transform: isSelected ? 'scale(1.01)' : 'scale(1)',
        cursor: 'pointer',
        backgroundColor: isSelected ? 'rgba(0,0,0,0.1)' : 'transparent',
      }}
    >
      {children}
    </div>
  );
}
