import React from 'react';
import { Header1Defaults } from './sections/Hero';
import { useEditor } from './editor-context';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import { Textarea } from 'src/components/ui/textarea';
import { Button } from 'src/components/ui/button';

export default function SectionEditor() {
  const { selectedSectionId } = useEditor();
  const config = getConfigFromType(selectedSectionId);
  return config;
}

function getConfigFromType(sectionType: string | null) {
  switch (sectionType) {
    case 'hero':
      return <HeroConfig />;

    default:
      return null;
  }
}

function HeroConfig() {
  const { sections, updateSection } = useEditor();

  const hero = sections.hero;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    updateSection('hero', { [key]: e.target.value });
  };
  return (
    <div>
      <h1>Hero Config</h1>
      <div className="space-y-2">
        <Label htmlFor="headline">Headline</Label>
        <Input
          id="headline"
          value={hero.content.headline}
          onChange={(e) => handleInputChange(e, 'headline')}
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="bodyText">Body Text</Label>
        <Textarea
          id="bodyText"
          value={hero.content.bodyText}
          onChange={(e) => handleInputChange(e, 'bodyText')}
          rows={4}
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="image">Image</Label>
        <div className="border-2 border-dashed rounded-lg p-4 text-center">
          {hero.content.image ? (
            <div className="relative aspect-video">
              <img
                src={hero.content.image?.src || '/placeholder.svg'}
                alt=""
                className="rounded-lg object-cover"
              />
              <Button
                variant="secondary"
                className="absolute top-2 right-2"
                onClick={() => updateSection(hero.id, { image: null })}
              >
                Remove
              </Button>
            </div>
          ) : (
            <Input
              id="image"
              type="file"
              className="hidden"
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  updateSection(hero.id, {
                    image: { src: URL.createObjectURL(e.target.files[0]), alt: '' },
                  });
                }
              }}
            />
          )}
          <Label
            htmlFor="image"
            className="cursor-pointer text-muted-foreground hover:text-foreground"
          >
            Click to upload image
          </Label>
        </div>
      </div>
    </div>
  );
}
