import { Button } from '@relume_io/relume-ui';
import type { ButtonProps } from '@relume_io/relume-ui';
import { useEditor } from '../editor-context';

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  headline: string;
  bodyText: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type Header1Props = React.ComponentPropsWithoutRef<'section'> & Partial<Props>;

export const Hero = (props: Header1Props) => {
  const { sections, selectedSectionId, selectSection } = useEditor();

  const { headline, bodyText, buttons, image } = {
    ...sections.hero.content,
    ...props,
  } as Props;
  return (
    <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28">
      <div className="container">
        <div className="grid grid-cols-1 gap-x-20 gap-y-12 md:gap-y-16 lg:grid-cols-2 lg:items-center">
          <div>
            <h1 className="mb-5 text-6xl font-bold md:mb-6 md:text-9xl lg:text-10xl">{headline}</h1>
            <p className="md:text-md">{bodyText}</p>
            <div className="mt-6 flex flex-wrap gap-4 md:mt-8">
              {/* {buttons.map((button, index) => (
                <Button key={index} {...button}>
                  {button.title}
                </Button>
              ))} */}
            </div>
          </div>
          <div>
            <img src={image?.src} className="w-full object-cover" alt={image?.alt} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const Header1Defaults: Header1Props = {
  headline: 'Medium length hero heading goes here',
  bodyText:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  buttons: [{ title: 'Button' }, { title: 'Button', variant: 'secondary' }],
  image: {
    src: 'https://d22po4pjz3o32e.cloudfront.net/placeholder-image.svg',
    alt: 'Relume placeholder image',
  },
};
